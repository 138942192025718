import React, { Suspense, lazy } from 'react'
import { history } from '@/store/reducers'
import { Alert } from '@/Components/Alert/Alert'
import { Layout, Modals, PageLoader } from '@components/layouts'
import { Route, Switch } from 'react-router-dom'
import { AccessHelper } from '@frontend-modules/access-controller'
import Home from '@/Screens/Home/Home'
import { ConnectedRouter as Router } from 'connected-react-router'
import { Chat } from '@/Screens/Chat/Chat'
import { ErrorBoundary } from '@frontend-modules/error-handler'

/**
 * @description - старые legacy routes
 */
const ProcessComplete = lazy(() => import('../Screens/Process/Complete/Complete'))
const SendCourier = lazy(() => import('../Screens/Process/SendCourier/SendCourier'))
const SendInternalCourier = lazy(() => import('../Screens/Process/SendInternalCourier/SendInternalCourier'))
const Delivered = lazy(() => import('../Screens/Process/Delivered/Delivered'))
const GenerateReport = lazy(() => import('../Screens/Process/GenerateReport/GenerateReport'))
const NextStage = lazy(() => import('../Screens/Process/NextStage/NextStage'))
const DownloadReport = lazy(() => import('../Screens/Process/DownloadReport'))
const AddChildren = lazy(() => import('../Screens/Process/AddChild/AddChild'))
const RecordTrial = lazy(() => import('../Screens/Process/RecordTrial/RecordTrial'))
const DistributeOnCourse = lazy(() => import('../Screens/Process/DistributeOnCourse/DistributeOnCourse'))
const Document = lazy(() => import('../Screens/Document/Document'))
const DistributeToSchool = lazy(() => import('../Screens/Process/DistributeToSchool/DistributeToSchool'))
const FillContract = lazy(() => import('../Screens/Process/FillContract/FillContract'))
const AddBuisnessProcess = lazy(() => import('../Screens/AddBuisnessProcess/AddBuisnessProcess'))
const AddParent = lazy(() => import('../Screens/AddParent/AddParent'))
const ChangeSchool = lazy(() => import('../Screens/Process/ChangeSchool/ChangeSchool'))
const DocumentToParent = lazy(() => import('../Screens/DocumentToParent/DocumentToParent'))
const Classes = lazy(() => import('../Screens/Classes/Classes'))
const ChooseStudent = lazy(() => import('../Screens/ChooseStudent'))
const Contracts = lazy(() => import('../Screens/Contracts/Contracts'))
const CreateContract = lazy(() => import('../Screens/Process/CreateContract/CreateContract'))
const SetDocumentList = lazy(() => import('../Screens/Process/SetDocumentList/SetDocumentList'))
const UserVisits = lazy(() => import('../Screens/UserLogging/UserVisits'))
const Ancete = lazy(() => import('../Screens/Ancete/Ancete'))
const GeneratedDocuments = lazy(() => import('../Screens/GeneratedDocuments/GeneratedDocuments'))
const Remove = lazy(() => import('../Screens/Remove/Remove'))
const NewDocuments = lazy(() => import('../Screens/NewDocuments/NewDocuments'))
const GenerateOffer = lazy(() => import('../Screens/Process/GenerateOffer/GenerateOffer'))
const Analytics = lazy(() => import('../Screens/Analytics/Analytics'))
const FinishedProcesses = lazy(() => import('../Screens/FinishedProcesses'))
const CourseGroups = lazy(() => import('../Screens/CourseGroups'))
const ManagerInfo = lazy(() => import('../Screens/UserLogging/ManagerInfo'))

/**
 * @description - legacy роуты и функционал которые уже переделаны
 * но не удалены по причинам неизвестно сломается ли что)
 */
const ParentCard = lazy(() => import('../Screens/Card/ParentCard'))
const StudentCard = lazy(() => import('../Screens/Card/StudentCard'))
const Message = lazy(() => import('../Screens/Message/Message'))
const Payment = lazy(() => import('../Screens/Payment/Payment'))
const FrozenPayment = lazy(() => import('../Screens/FrozenPayment/FrozenPayment'))
// СhooseFrozenPayment тут русская буква если что первая
const ChooseFrozenPayment = lazy(() => import('../Screens/СhooseFrozenPayment/ChooseFrozenPayment'))
const UnfrozenPayment = lazy(() => import('../Screens/./UnfrozenPayment/UnfrozenPayment'))
const AdditionalCoursesOld = lazy(() => import('../Screens/./AdditionalCourses'))

/**
 * @description - новые нормальные роуты
 */
import { Login } from '@pages'
import { ERROR_BOUNDERY_SHOW_BUTTON } from '@/src-new/constants'
import { useOGChatWidget } from '@/hooks/use-og-chat-widget'
const Debts = lazy(() => import('@pages/debts'))
const Tasks = lazy(() => import('@pages/tasks'))
const Task = lazy(() => import('@pages/task'))
const ProcessesMonitoring = lazy(() => import('@pages/processes-monitoring'))
const Parents = lazy(() => import('@pages/parents'))
const Payments = lazy(() => import('@pages/payments'))
const AdditionalCoursesNew = lazy(() => import('@pages/additional-courses'))
const GroupActions = lazy(() => import('@pages/group-actions'))
const Decree = lazy(() => import('@pages/decree'))

const Routes = () => {
	const isAuthorization = window.location.pathname === '/login'
	useOGChatWidget(isAuthorization)

	return (
		<Router history={history}>
			<Chat />
			<Alert />
			<Modals />
			<Switch>
				<Route path={'/login'} component={Login} exact />
				<Layout>
					<Suspense fallback={<PageLoader />}>
						<Switch>
							{AccessHelper.renderComponentByAccessKey(
								'parentCard',
								<Route
									path={'/parents'}
									component={Parents}
									render={() => (
										<ErrorBoundary
											key={'parents'}
											errorMessage="Parents page has crashed"
											showAlertReportBtn={ERROR_BOUNDERY_SHOW_BUTTON}
										>
											<Parents />
										</ErrorBoundary>
									)}
									exact
								/>,
							)}
							{AccessHelper.renderComponentByAccessKey(
								'debts',
								<Route
									path={'/debts/'}
									exact
									render={() => (
										<ErrorBoundary
											key={'debts'}
											errorMessage="Debts page has crashed"
											showAlertReportBtn={ERROR_BOUNDERY_SHOW_BUTTON}
										>
											<Debts />
										</ErrorBoundary>
									)}
								/>,
							)}
							{AccessHelper.renderComponentByAccessKey(
								'payments',
								<Route
									path={'/payments'}
									render={() => (
										<ErrorBoundary
											key={'payments'}
											errorMessage="payments page has crashed"
											showAlertReportBtn={ERROR_BOUNDERY_SHOW_BUTTON}
										>
											<Payments />
										</ErrorBoundary>
									)}
								/>,
							)}
							{AccessHelper.renderComponentByAccessKey(
								'groupActions',
								<Route
									path={'/group-actions'}
									render={() => (
										<ErrorBoundary
											key={'group actions'}
											errorMessage="group actions page has crashed"
											showAlertReportBtn={ERROR_BOUNDERY_SHOW_BUTTON}
										>
											<GroupActions />
										</ErrorBoundary>
									)}
								/>,
							)}
							{AccessHelper.renderComponentByAccessKey(
								'tasks',
								<Route
									path={'/new/tasks/'}
									render={() => (
										<ErrorBoundary
											key={'tasks'}
											errorMessage="Task page has crashed"
											showAlertReportBtn={ERROR_BOUNDERY_SHOW_BUTTON}
										>
											<Tasks />
										</ErrorBoundary>
									)}
									exact
								/>,
							)}
							{AccessHelper.renderComponentByAccessKey(
								'addEdMonitor',
								<Route
									path={'/additional-courses-new'}
									exact
									render={() => (
										<ErrorBoundary
											key={'addEdMonitor'}
											errorMessage="addEdMonitor page has crashed"
											showAlertReportBtn={ERROR_BOUNDERY_SHOW_BUTTON}
										>
											<AdditionalCoursesNew />
										</ErrorBoundary>
									)}
								/>,
							)}
							{AccessHelper.renderComponentByAccessKey(
								'tasks',
								<Route
									path={'/new/tasks/:id/'}
									exact
									render={(props) => (
										<ErrorBoundary
											key={'task'}
											errorMessage="task page has crashed"
											showAlertReportBtn={ERROR_BOUNDERY_SHOW_BUTTON}
										>
											<Task {...props} />
										</ErrorBoundary>
									)}
								/>,
							)}
							{AccessHelper.renderComponentByAccessKey(
								'groupOrders',
								<Route
									path={'/decree'}
									render={() => (
										<ErrorBoundary
											key={'decree'}
											errorMessage="decree page has crashed"
											showAlertReportBtn={ERROR_BOUNDERY_SHOW_BUTTON}
										>
											<Decree />
										</ErrorBoundary>
									)}
									exact
								/>,
							)}
							{AccessHelper.renderComponentByAccessKey(
								'processes_monitoring',
								<Route
									path={'/processes-monitoring'}
									render={() => (
										<ErrorBoundary
											key={'processes-monitoring'}
											errorMessage="processes-monitoring page has crashed"
											showAlertReportBtn={ERROR_BOUNDERY_SHOW_BUTTON}
										>
											<ProcessesMonitoring />
										</ErrorBoundary>
									)}
									exact
								/>,
							)}

							{AccessHelper.renderComponentByAccessKey(
								'parentCard',
								<Route path={'/card/parent/'} component={ParentCard} exact />,
							)}
							{AccessHelper.renderComponentByAccessKey(
								'parentCard',
								<Route path={'/add/parent/'} component={AddParent} exact />,
							)}
							{AccessHelper.renderComponentByAccessKey(
								'oldProcesses',
								<Route path={'/'} component={Home} exact />,
							)}
							{AccessHelper.renderComponentByAccessKey(
								'messages',
								<Route path={'/message'} component={Message} exact />,
							)}
							{AccessHelper.renderComponentByAccessKey(
								'studentCard',
								<Route path={'/card/student/'} component={StudentCard} exact />,
							)}
							{AccessHelper.renderComponentByAccessKey(
								'studentCard',
								<Route path={'/card/student/ancete/'} component={Ancete} exact />,
							)}
							{AccessHelper.renderComponentByAccessKey(
								'studentCard',
								<Route path={'/choose/student'} component={ChooseStudent} exact />,
							)}
							{AccessHelper.renderComponentByAccessKey(
								'analytics',
								<Route path={'/analytics/'} component={Analytics} exact />,
							)}
							{AccessHelper.renderComponentByAccessKey(
								'oldFreezed',
								<Route path={'/frozen-payment/'} component={ChooseFrozenPayment} exact />,
							)}
							{AccessHelper.renderComponentByAccessKey(
								'oldFreezed',
								<Route path={'/frozen-payment/current/'} component={FrozenPayment} exact />,
							)}
							{AccessHelper.renderComponentByAccessKey(
								'oldFreezed',
								<Route path={'/unfrozen-payment/'} component={UnfrozenPayment} exact />,
							)}
							{AccessHelper.renderComponentByAccessKey(
								'oldAddEd',
								<Route path={'/additional-courses/'} component={AdditionalCoursesOld} exact />,
							)}
							{AccessHelper.renderComponentByAccessKey(
								'oldPayment',
								<Route path={'/payment/'} component={Payment} exact />,
							)}
							{AccessHelper.renderComponentByAccessKey(
								'studentGroups',
								<Route path={'/classes/'} component={Classes} exact />,
							)}
							{AccessHelper.renderComponentByAccessKey(
								'courseGroups',
								<Route path="/course-groups/" component={CourseGroups} exact />,
							)}
							{AccessHelper.renderComponentByAccessKey(
								'oldTasksFinished',
								<Route path={'/finished-tasks/'} component={FinishedProcesses} exact />,
							)}
							{AccessHelper.renderComponentByAccessKey(
								'userVisits',
								<Route path={'/user-visits/'} component={UserVisits} exact />,
							)}
							{AccessHelper.renderComponentByAccessKey(
								'managerInfo',
								<Route path={'/manager-info/'} component={ManagerInfo} exact />,
							)}
							{AccessHelper.renderComponentByAccessKey(
								'userRemove',
								<Route path={'/remove/'} component={Remove} exact />,
							)}
							{AccessHelper.renderComponentByAccessKey(
								'oldProcessCreate',
								<Route path={'/create-process'} component={AddBuisnessProcess} exact />,
							)}
							{AccessHelper.renderComponentByAccessKey(
								'oldProcessCreate',
								<Route path={'/contracts'} component={Contracts} exact />,
							)}
							<Route path={'/process/complete/'} component={ProcessComplete} exact />
							<Route path={'/process/complete/'} component={ProcessComplete} exact />
							<Route path={'/process/complete/'} component={ProcessComplete} exact />
							<Route path={'/process/send_courier/'} component={SendCourier} exact />
							<Route path={'/process/send_internal_courier/'} component={SendInternalCourier} exact />
							<Route path={'/process/delivered/'} component={Delivered} exact />
							<Route path={'/process/generate_report/'} component={GenerateReport} exact />
							<Route path={'/process/generated_documents/'} component={GeneratedDocuments} exact />
							<Route path={'/process/generate_offer/'} component={GenerateOffer} exact />
							<Route path={'/process/download_report/'} component={DownloadReport} exact />
							<Route path={'/process/add_child/'} component={AddChildren} exact />
							<Route path={'/process/record_trial/'} component={RecordTrial} exact />
							<Route path={'/process/distribute_on_course/'} component={DistributeOnCourse} exact />
							<Route path={'/process/distribute_to_school/'} component={DistributeToSchool} exact />
							<Route path={'/process/upload_file/'} component={Document} exact />
							<Route path={'/process/approve_documents/'} component={Document} exact />
							<Route path={'/process/upload_documents/'} component={NewDocuments} exact />
							<Route path={'/process/document_to_parent/'} component={DocumentToParent} exact />
							<Route path={'/process/create_contract/'} component={CreateContract} exact />
							<Route path={'/process/change_contract/'} component={FillContract} exact />
							<Route path={'/process/fill_contract/'} component={FillContract} exact />
							<Route path={'/process/correct_contract/'} component={FillContract} exact />
							<Route path={'/process/validate_parent'} component={ParentCard} exact />
							<Route path={'/process/validate_student'} component={StudentCard} exact />
							<Route path={'/process/set_documents_list'} component={SetDocumentList} exact />
							<Route path={'/process/:process_name'} component={NextStage} exact />
							<Route path={'/process/change_school/'} component={ChangeSchool} exact />
							<Route path={'/process/change_school_from_other_process/'} component={ChangeSchool} exact />
						</Switch>
					</Suspense>
				</Layout>
			</Switch>
		</Router>
	)
}

export default Routes
