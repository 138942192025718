import { $BaseHttpClient } from '@utils/http/BaseHttpClient'
import { AxiosError, AxiosResponse } from 'axios'
import { getAccessToken } from '@/common/token'
import { HttpClient as FormGeneratorHttp } from '@frontend-modules/form-generator'
import { ErrorsCaptureWrapperApi } from '@frontend-modules/error-handler'
import { API_ERROR_HANDLER_SHOW_BUTTON } from '@/src-new/constants'
import { UntraceableApiList } from './sentryApiLists'
import { onResponseErrorHandler } from '@/common/axios'

// TODO: переписать способ обновления токена
// на обновление только 1 раз за все запросы
const $HttpClient = new $BaseHttpClient({
	_baseURL: process.env.REACT_APP_HOST,
	_headers: {
		'Content-type': 'application/json; charset=UTF-8',
	},
})

const httpClientErrorListener = (httpClient, callback) => {
	httpClient.instance.interceptors.response.use(
		async (response) => response,
		async (error) => {
			callback?.(error)
			return error
		},
	)
}

$HttpClient.instance.interceptors.request.use(async (config) => {
	const accessToken = getAccessToken()
	if (accessToken) {
		if (config) {
			config.headers['Authorization'] = `Bearer ${accessToken}`
		}
	}
	return config
})
const NewAxiosResponder = (on403Callback) => {
	$HttpClient.instance.interceptors.response.use(
		(response: AxiosResponse) => response.data,
		async (error: AxiosError) => {
			const { type, data } = await onResponseErrorHandler(error, on403Callback)

			if (type === 'config' && data) {
				return $HttpClient.instance(data)
			}

			ErrorsCaptureWrapperApi({
				error: error,
				UntraceableApiList,
				showAlertReportBtn: API_ERROR_HANDLER_SHOW_BUTTON,
			})
			throw error
		},
	)
}
FormGeneratorHttp.setClient($HttpClient.instance as any)

export { $HttpClient, httpClientErrorListener, NewAxiosResponder }
