import { setAccessToken, setRefreshToken, updateRefreshToken } from '@/common/token'
import { AxiosError, AxiosRequestConfig } from 'axios'
import { on401Logout } from '@/common/logout'

type TOn401ErrorType = 'error' | 'config'
interface IOn401ErrorReturn {
	type: TOn401ErrorType
	data?: AxiosRequestConfig
}
export const onResponseErrorHandler = async (
	error: AxiosError,
	on403Callback: () => void,
): Promise<IOn401ErrorReturn> => {
	const errorData = error.response
	const originalConfig = error.config

	const onLocalLogout401 = (): IOn401ErrorReturn => {
		if (window.location.pathname !== '/login') {
			on401Logout({ isSaveLocation: true })
		}
		return { type: 'error' }
	}

	if (errorData.status === 403 && window.location.pathname !== '/login') {
		on403Callback()
		return { type: 'error' }
	}

	if (errorData.status === 401 && window.location.pathname !== '/login') {
		try {
			const newTokens = await updateRefreshToken()
			const { access, refresh } = newTokens?.data || {}
			if (refresh) {
				setRefreshToken(refresh)
			}
			if (access) {
				setAccessToken(access)
			}
			return { type: 'config', data: originalConfig }
		} catch (e) {
			return onLocalLogout401()
		}
	}
}
